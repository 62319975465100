import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Log from "../components/log/log";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Log mdxType="Log" />
    <Metatags title="Journal App | May 30 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-30.png" url="https://cssgrid31.brett.cool/" pathname="/may-30" mdxType="Metatags" />
    <Footer date={30} prev={true} next={true} mdxType="Footer">
      <p>{`This past month has gone really quickly, all things considered.`}</p>
      <p>{`In the past few years, I’ve been more mindful about keeping in the present, but also, putting an effort into collecting thoughts and memories.`}</p>
      <p>{`Here’s a quick mockup of a journalling app I’d like to use, that so far, doesn’t exist (Day One is pretty nice, but for now my `}<a parentName="p" {...{
          "href": "https://brett.cool/blog/2019/01/14/using-ios-shortcuts-for-journaling/",
          "title": "Using iOS Shortcuts + iA Writer to reduce journaling friction"
        }}>{`low-fi`}</a>{` approach is working okay).`}</p>
      <p>{`I’d have liked to write more entries for this month, but my brain is pretty fried and I can’t even recall much of the last fortnight so far—glad the end is so very nearly in sight, I don’t think I can keep this pace up forever!`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      